<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixColorFieldInterface, VoixLinkFieldInterface, VoixNumberFieldInterface, VoixSelectFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    logo: VoixSelectFieldInterface
    color: VoixColorFieldInterface
    size: VoixNumberFieldInterface
    position: VoixSelectFieldInterface
    absolute: VoixCheckboxFieldInterface
    link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  preview: 'SlicesBrandingLogos.jpg',
  name: { label: 'Logo', group: 'Branding', layouts: ['*'] },
  slots: [{ name: 'default', allowedElements: [] }],
  description: '',
  fields: {
    logo: {
      type: 'select',
      label: 'Logo',
      options: {
        LogosAtlantisLogo: 'Atlantis Logo',
        LogosReefLogo: 'Reef Logo',
      },
      default: 'LogosAtlantisLogo',
    },

    color: {
      type: 'color',
      label: 'Color',
      enabled: false,
    },

    size: {
      type: 'number',
      label: 'Size',
      default: '200',
    },

    position: {
      type: 'select',
      label: 'Position',
      options: {
        left: 'Left',
        center: 'Center',
        right: 'Right',
      },
    },

    absolute: {
      type: 'checkbox',
      label: 'Absolute',
      default: false,
    },

    link: {
      type: 'link',
      label: 'Link',
      enabled: false,
    },
  },
  templates: [{
    label: 'Slider Container',
    fields: {
      logo: {
        value: 'LogosAtlantisLogo',
      },
      color: {
        value: '#000000',
        enabled: true,
      },
      size: {
        value: '100',
      },
    },
  }],
})

const logoStyles = computed(() => {
  const styles: { color?: string | null, width?: string | null } = {}
  if (props.fields.color.enabled)
    styles.color = props.fields.color.value

  if (props.fields.size.value)
    styles.width = `${props.fields.size.value}px`

  return styles
})
</script>

<template>
  <div :class="{ 'absolute z-50 top-0 left-0 right-0 flex justify-center': fields.absolute.value }">
    <div :class="{ 'big-container': fields.absolute.value }">
      <div
        class="flex " :class="{
          'px-4 py-2 lg:py-12 lg:px-12': fields.absolute.value,
          'justify-start': fields.position.value === 'left',
          'justify-center': fields.position.value === 'center',
          'justify-end': fields.position.value === 'right',
        }"
      >
        <VoixLink v-if="fields.link.enabled" :field="fields.link">
          <LazyLogosAtlantisLogo v-if="fields.logo.value === 'LogosAtlantisLogo'" :style="logoStyles" />
          <LazyLogosReefLogo v-if="fields.logo.value === 'LogosReefLogo'" :style="logoStyles" />
        </VoixLink>
        <template v-else>
          <LazyLogosAtlantisLogo v-if="fields.logo.value === 'LogosAtlantisLogo'" :style="logoStyles" />
          <LazyLogosReefLogo v-if="fields.logo.value === 'LogosReefLogo'" :style="logoStyles" />
        </template>
      </div>
    </div>
  </div>
</template>
